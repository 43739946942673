import axios from "axios";
import localeService from "./localeService";

const baseURL = process.env.VUE_APP_BACKEND_HOST;

const client = axios.create({
  baseURL,
});

const simpleGetRequest = (url, params = {}) => {
  return client
    .get(url, {
      params,
      headers: {
        "Accept-Language": localeService.getLocale(),
      },
    })
    .then(({ data }) => data);
};

const sendBeModel = async (requestForm, images) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(requestForm)) {
    formData.append(key, value);
  }

  for (let x = 0; x < images.length; x++) {
    formData.append(`image-${x}`, images[x].file);
  }

  return client.post("/models/be-a-model", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default {
  sendBeModel,
  initialData: () => simpleGetRequest(`/initial`),
  pageInfo: pageName => simpleGetRequest(`/pages/${pageName}`),
  news: (offset, quantity) =>
    simpleGetRequest(`/pages/news`, { offset, quantity }),
};
