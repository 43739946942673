<template>
  <div id="loading" :class="{ hidden }">
    <div id="loading-center">
      <div id="loading-center-absolute">
        <div class="object one"></div>
        <div class="object two"></div>
        <div class="object three"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    hidden: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
#loading {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-top: 0;
  top: 0;
  opacity: 1;
  transition: 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100px;
  width: 40px;
  margin-top: -50px;
  margin-left: -20px;
}
.object {
  width: 40px;
  height: 8px;
  margin-bottom: 20px;
  background-color: #000000;
  -webkit-animation: animate 0.8s infinite;
  animation: animate 0.8s infinite;
}

.object.two {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.object.three {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes animate {
  50% {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes animate {
  50% {
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
</style>
