<template>
  <div class="checkbox">
    <input :id="inputId" :required="required" :checked="modelValue" type="checkbox" @change="handleInput" />
    <span class="state-view"></span>
    <label v-html="label" :for="inputId"></label>
  </div>
</template>

<script>
import transliterate from "../../utils/transliterate";

export default {
  name: "checkbox",
  props: {
    modelValue: Boolean,
    required: Boolean,
    label: String,
  },
  computed: {
    inputId() {
      return transliterate(this.label);
    },
  },
  data() {
    return {};
  },
  methods: {
    setValue(value) {
      this.$emit("update:modelValue", value);
    },
    handleInput({ target }) {
      this.setValue(target.checked);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.checkbox {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 24px;

  input {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
    vertical-align: middle;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    &:checked + .state-view {
      background-image: url("/images/check.png");
      background-color: #000000;
    }
  }

  span.state-view {
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #000000;
    background: center no-repeat transparent;
    vertical-align: middle;
  }

  label {
    padding-left: 20px;
    display: inline-block;
    width: calc(100% - 24px);
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
  }
}
</style>
