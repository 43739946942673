import { createWebHistory, createRouter } from "vue-router";

import main from "./components/pages/main";
import school from "./components/pages/school";
import modelsList from "./components/pages/models/list";
import modelView from "./components/pages/models/view";
import beaModel from "./components/pages/be-a-model";
import about from "./components/pages/about";
import contacts from "./components/pages/contacts";
import privacy from "./components/pages/privacy";
import tos from "./components/pages/tos";
import pageNotFound from "./components/pages/404";

const pathWithLocale = path => {
  return `/:locale(ru|en)?${path}`;
};

const routes = [
  {
    path: pathWithLocale(""),
    name: "main",
    component: main,
  },
  {
    path: pathWithLocale("/models"),
    name: "models",
    component: modelsList,
  },
  {
    path: pathWithLocale("/models/:model([\\w\\d\\-\\_]+)"),
    name: "models-view",
    component: modelView,
  },
  {
    path: pathWithLocale("/school"),
    name: "school",
    component: school,
  },
  {
    path: pathWithLocale("/be-a-model"),
    name: "be-a-model",
    component: beaModel,
  },
  {
    path: pathWithLocale("/about"),
    name: "about",
    component: about,
  },
  {
    path: pathWithLocale("/contacts"),
    name: "contacts",
    component: contacts,
  },
  {
    path: pathWithLocale("/privacy"),
    name: "privacy",
    component: privacy,
  },
  {
    path: pathWithLocale("/tos"),
    name: "tos",
    component: tos,
  },
  {
    path: "/:pathMatch(.*)",
    name: "page-not-found",
    component: pageNotFound,
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
