<template>
  <div class="page-content" ref="pageWrap">
    <div class="container" ref="container">
      <div class="row">
        <div class="col xs12">
          <div class="title">Страница не найдена</div>
          <div class="text">
            Возможно, был введен некоректный адрес<br />
            либо страница была перемещена
          </div>
          <router-link class="button" :to="{ params: routerParams, name: 'main' }"> Вернуться на главную </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageNotFound",
  props: {
    routerParams: Object,
  },
  data() {
    return {};
  },
  methods: {
    updateContentOffset() {
      const fullHeight = this.$refs.pageWrap.offsetHeight;
      const newOffset = (fullHeight / 100) * 46;

      this.$refs.container.style.paddingTop = `${newOffset}px`;
    },
  },
  mounted() {
    this.updateContentOffset();

    window.onresize = () => {
      this.updateContentOffset();
    };
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.page-content {
  background: url("/images/404-bg.png") center/cover no-repeat;
  height: 100%;
  padding: 0;

  .container {
    color: #000000;
    text-align: center;

    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 40px;
      text-transform: uppercase;
    }

    .text {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 40px;
    }

    a.button {
      display: inline-block;
      width: 280px;
    }
  }
}
</style>
