const alphabet = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "yo",
  ж: "zh",
  з: "z",
  и: "i",
  й: "y",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "h",
  ц: "c",
  ч: "ch",
  ш: "sh",
  щ: "shc",
  ъ: "",
  ы: "",
  ь: "",
  э: "a",
  ю: "yu",
  я: "ya",
  "-": "_",
  _: "_",
  " ": "_",
};

export default text => {
  return Array.prototype.map
    .call(text, letter => {
      const character = letter.toLowerCase();
      const isNumeric = !!Number(letter);

      return isNumeric ? letter : alphabet[character] || letter;
    })
    .slice(0, 20)
    .join("");
};
