<template>
  <div class="text-input" :class="{ active: inputIsActive, 'has-value': modelValue.length }">
    <input
      :id="inputId"
      :value="modelValue"
      :type="type"
      :required="required"
      @input="handleInput"
      @focus="inputIsActive = true"
      @blur="inputIsActive = false"
      spellcheck="false"
      autocomplete="off"
      maxlength="150"
    />
    <label :for="inputId">{{ label + `${required ? " *" : ""}` }}</label>
    <span class="clear" :class="{ shown: modelValue.length }" @click="setValue('')"></span>
  </div>
</template>

<script>
import transliterate from "../../utils/transliterate";

export default {
  name: "text-input",
  props: {
    modelValue: String,
    label: String,
    required: Boolean,
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    inputId() {
      return transliterate(this.label);
    },
  },
  data() {
    return {
      inputIsActive: false,
    };
  },
  methods: {
    setValue(value) {
      this.$emit("update:modelValue", value);
    },
    handleInput({ target }) {
      this.setValue(target.value);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.text-input {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    &:after {
      width: 100%;
    }
  }

  &.active,
  &.has-value {
    &:before,
    &:after {
      width: 100%;
    }

    label {
      font-size: 14px;
      line-height: 20px;
      left: 0;
      top: -22px;
    }
  }

  &:before,
  &:after {
    z-index: 1;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: #f4f4f4;
    transition: 0.4s ease-in;
  }

  &:after {
    top: auto;
    bottom: 0;
    height: 2px;
    background: #505050;
  }

  input {
    background: transparent;
    z-index: 2;
    position: relative;
    display: block;
    padding: 0 35px 0 0;
    width: 100%;
    height: 45px;
    border: none;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    outline: none;
    text-indent: 15px;
    border-bottom: 1px solid #505050;
  }

  label {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 15px;
    line-height: 45px;
    font-size: 18px;
    font-weight: 400;
    color: #aaaaaa;
    transition: 0.25s ease-in-out;
  }

  .clear {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    background: url("/images/cross.png") center no-repeat transparent;

    &.shown {
      pointer-events: unset;
      opacity: 1;
    }
  }
}
</style>
