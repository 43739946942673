<template>
  <div class="page-content">
    <div class="container" v-if="content">
      <div class="row">
        <div class="col xs12 l6">
          <div class="cover"><div class="logo"></div></div>
        </div>
        <div class="col xs12 l6">
          <div class="description" v-html="content"></div>
        </div>
      </div>
      <div class="team">
        <div class="team-heading container-paddings">{{ translations.team }}</div>
        <div class="team-members">
          <div class="row">
            <div class="col xs12 m4 l3" v-for="(member, index) of team" v-bind:key="index">
              <div class="team-member">
                <div class="member-cover" :style="{ backgroundImage: `url('${member.image}')` }"></div>
                <div class="member-name">{{ member.name }}</div>
                <div class="member-position">{{ member.position }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backend from "../../services/backend";

export default {
  name: "about",
  props: {
    locale: String,
  },
  data() {
    return {
      content: null,
      team: [],
      translations: null,
    };
  },
  methods: {
    async updatePageData() {
      const { translations, content, team } = await backend.pageInfo("about");

      this.translations = translations;
      this.content = content;
      this.team = team;
    },
  },
  async mounted() {
    await this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cover {
  width: 100%;
  height: 750px;
  background: url("/images/about-cover.png") center/cover no-repeat;
  position: relative;

  .logo {
    height: 100%;
    width: 58px;
    position: absolute;
    left: -84px;
    top: 0;
    background: url("/images/about-vertical-logo.png?1") center/cover no-repeat;
  }
}

.description {
  padding-left: 10px;

  ::v-deep(h1) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 60px 0;

    strong {
      font-weight: 700;
    }
  }

  ::v-deep(ul) {
    padding: 0;

    li {
      list-style: none;
      text-indent: 0;
      position: relative;
      display: block;
      margin-bottom: 20px;
      padding-left: 20px;

      &:before {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        left: 0;
        top: 8px;
        background: #000000;
        content: "";
      }
    }
  }

  ::v-deep(p) {
    font-size: 16px;
    line-height: 22.4px;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }
}

.team {
  margin-top: 150px;

  .team-heading {
    font-size: 48px;
    line-height: 48px;
    padding-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 2px solid #000000;
  }

  .team-members {
    padding-top: 50px;

    .member-cover {
      width: 100%;
      height: 405px;
      background: center/cover no-repeat;
    }

    .member-name {
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
      margin: 30px 0 15px 0;
    }

    .member-position {
      font-size: 16px;
    }
  }
}

.laptop {
  .cover {
    height: 568px;

    .logo {
      display: none;
    }
  }

  .team-members {
    .member-cover {
      height: 326px;
    }
  }
}

.tablet {
  .cover {
    height: 749px;

    background-position: 100px center;
    background-size: contain;

    .logo {
      left: 0;
    }
  }

  .team {
    margin-top: 100px;

    .team-heading {
      font-size: 36px;
    }

    .team-members {
      .member-cover {
        height: 332px;
      }
    }
  }

  .description {
    ::v-deep(h1) {
      margin-top: 80px;
    }
  }
}

.phone {
  .cover {
    height: 566px;
  }

  .team {
    .team-heading {
      font-size: 36px;
    }

    .team-members {
      .member-cover {
        height: 630px;
      }
    }
  }

  .description {
    ::v-deep(h1) {
      margin-top: 70px;
    }
  }
}

.smallest {
  .cover {
    height: 360px;
  }

  .team {
    .team-heading {
      font-size: 24px;
    }
  }

  .description {
    ::v-deep(h1) {
      margin-top: 40px;
    }
  }
}

.phoneMinus {
  .cover {
    .logo {
      display: none;
    }
  }

  .team {
    margin-top: 90px;

    .team-member {
      margin-bottom: 60px;
    }
  }
}
</style>
