const getUrlLocale = () => {
  const currentPath = location.pathname;
  const [, locale] = currentPath.split("/");

  return locale;
};

let locale = getUrlLocale();
let defaultLocale = getUrlLocale();
const initialLocale = getUrlLocale();

export default {
  setLocale: desiredLocale => (locale = desiredLocale),
  getLocale: () => locale,
  setDefaultLocale: desiredLocale => (defaultLocale = desiredLocale),
  isDefaultUsed: () => locale === defaultLocale,
  defaultIsInitial: () => initialLocale === defaultLocale,
};
