<template>
  <div class="page-content">
    <div class="background">
      <div class="container logo"></div>
    </div>
    <div class="container">
      <div class="blog">
        <div class="blog-heading container-paddings">Blog</div>
        <div class="blog-items">
          <div class="row">
            <div class="col xs12 s6 m4" v-for="(item, index) of newsList" v-bind:key="index">
              <div class="blog-item">
                <div class="cover" :style="{ backgroundImage: 'url(' + item.cover + ')' }"></div>
                <div class="description">{{ item.title }}</div>
                <div class="date">{{ item.date }}</div>
                <div class="backflip" :class="{ opened: !!item.opened }">
                  <div class="links">
                    <div class="instagram">
                      Посмотреть новость в <a target="_blank" :href="item.instagramLink">Instagram</a>
                    </div>
                    <div class="model" v-for="model of item.models" v-bind:key="model.id">
                      Модель:
                      <router-link :to="{ params: { model: model.id, ...routerParams }, name: 'models-view' }">
                        {{ model.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <a class="backflip-opener" href="javascript: void(0);" v-on:click="item.opened = !item.opened"></a>
              </div>
            </div>
          </div>
          <div class="row" v-if="loadMode">
            <div class="col xs12">
              <div class="show-more">
                <button class="button" :class="{ disabled: loading }" @click="loadNews(newsList.length)">
                  {{ translations.showMore }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backend from "../../services/backend";

export default {
  name: "main-page",
  props: {
    locale: String,
    routerParams: Object,
  },
  data() {
    return {
      newsList: [],
      loadMode: false,
      loading: false,
      translations: {},
    };
  },
  mounted() {
    this.updatePageData();
  },
  methods: {
    loadNews(offset) {
      const refresh = offset === 0;
      const quantity = offset === 0 ? 9 : 6;

      this.loading = true;

      backend
        .news(offset, quantity)
        .then(({ news, hasMore, translations }) => {
          if (refresh) {
            this.newsList = [];
          }

          this.newsList = [...this.newsList, ...news];
          this.loadMode = hasMore;
          this.translations = translations;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePageData() {
      this.loadNews(0);
    },
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.background {
  background: url("/images/main-bg.png") center/cover no-repeat;
  height: 580px;

  .logo {
    height: 100%;
    background: url("/images/main-logo.svg?1") center bottom 40px / contain no-repeat;
    opacity: 0.75;
  }
}

.blog {
  margin-top: 150px;

  .show-more {
    text-align: center;
    font-size: 0;
    margin-top: 20px;

    .button {
      display: inline-block;
      width: 220px;
    }
  }

  .blog-heading {
    font-size: 48px;
    line-height: 48px;
    padding-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 2px solid #000000;
  }

  .blog-items {
    padding-top: 50px;

    .blog-item {
      margin-bottom: 100px;
      position: relative;

      .cover {
        height: 380px;
        background: center/cover no-repeat;
      }

      .description {
        height: 50px;
        margin: 30px 0;
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        overflow: hidden;
      }

      .date {
        text-transform: uppercase;
        color: #aaaaaa;
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 6px;
      }

      .backflip {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        background: #111111;
        transition: 0.2s ease-in-out;

        .links {
          transition: opacity 0.4s ease-out;
          width: 0;
          height: 0;
          opacity: 0;
          display: none;
          padding-top: 180px;
          padding-left: 40px;
          color: #aaaaaa;
          font-size: 16px;

          & > div {
            margin-bottom: 30px;

            &.instagram {
              margin-bottom: 50px;
            }
          }

          a {
            color: #ffffff;
          }
        }

        &.opened {
          width: 100%;
          height: 100%;

          .links {
            opacity: 1;
            width: unset;
            height: unset;
            display: block;
          }
        }
      }

      .backflip.opened + .backflip-opener,
      &:hover .backflip-opener {
        background-image: url("/images/arrow-white.png");
        background-color: #111111;
      }

      .backflip-opener {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 46px;
        width: 90px;
        background: url("/images/arrow-black.png") center no-repeat transparent;
      }
    }
  }
}

@media (max-width: 1366px) {
  .background {
    height: 380px;
  }
}

.laptopMinus .blog {
  margin-top: 100px;

  .blog-item {
    margin-bottom: 80px;
  }
}

.laptop {
  .background {
    height: 355px;
  }

  .blog .blog-items .blog-item {
    .cover {
      height: 307px;
    }
    .links {
      padding: 60px 0 0 20px;
    }
  }
}

.tablet {
  .background {
    height: 270px;
  }

  .blog {
    .blog-heading {
      font-size: 36px;
    }

    .blog-items .blog-item {
      .cover {
        height: 229px;
      }
      .links {
        padding: 60px 0 0 20px;

        & > div {
          margin-bottom: 10px;
          &.instagram {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.phoneMinus {
  .background {
    height: 196px;
  }
  .blog {
    .blog-item {
      margin-bottom: 40px;
    }
  }
}

.phone {
  .blog {
    margin-top: 70px;

    .blog-heading {
      font-size: 36px;
    }

    .blog-items .blog-item {
      .cover {
        height: 210px;
      }
      .links {
        padding: 60px 0 0 10px;

        & > div {
          margin-bottom: 10px;
          &.instagram {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.smallest {
  .page-content {
    padding-top: 50px;
  }

  .background {
    background-position: left 26% center;
  }

  .blog {
    margin-top: 50px;

    .blog-heading {
      font-size: 24px;
      padding: 0;
    }

    .blog-items .blog-item {
      .cover {
        height: 280px;
      }
      .links {
        padding: 100px 0 0 15px;
      }
    }
  }
}
</style>
