<template>
  <div class="page-content">
    <div class="container school" v-if="content">
      <h2 class="heading" v-html="content.heading"></h2>
      <p v-html="content.text"></p>
    </div>
  </div>
</template>

<script>
import backend from "../../services/backend";

export default {
  name: "school",
  props: {
    locale: String,
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    async updatePageData() {
      this.content = await backend.pageInfo("school");
    },
  },
  mounted() {
    this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.page-content {
  background: url("/images/school-bg.jpg") center/cover no-repeat #a29c9e;
  background-size: cover/80%;
  padding: 230px 0 330px 0;
}

.school {
  color: #ffffff;

  .heading {
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
    width: 50%;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 24px;
  }
}

.laptopMinus {
  .page-content {
    padding: 150px 0;
  }
  .heading {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }
}

.tabletMinus {
  .page-content {
    padding: 180px 0;
  }
}

.phoneMinus {
  .page-content {
    padding: 130px 0;
    background-image: unset;
  }

  .heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

  p {
    text-align: center;
    font-size: 16px;
  }
}
</style>
