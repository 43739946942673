<template>
  <div class="range">
    <label :for="inputId"
      >{{ label }} <span>{{ modelValue }}</span></label
    >
    <input :id="inputId" :value="modelValue" :min="min" :max="max" type="range" @input="handleInput" />
  </div>
</template>

<script>
import transliterate from "../../utils/transliterate";

export default {
  name: "range",
  props: {
    modelValue: Number,
    label: String,
    min: Number,
    max: Number,
  },
  computed: {
    inputId() {
      return transliterate(this.label);
    },
  },
  data() {
    return {};
  },
  methods: {
    setValue(value) {
      this.$emit("update:modelValue", Number(value));
    },
    handleInput({ target }) {
      this.setValue(target.value);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.range {
  label {
    font-size: 16px;
    color: #aaaaaa;
    font-weight: 400;

    span {
      padding-left: 10px;
      color: #000000;
    }
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    height: 1px;
    background: #505050;
    outline: none;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 30px;
    background: #000;
    cursor: pointer;
  }

  input::-moz-range-thumb {
    width: 10px;
    height: 30px;
    background: #000;
    cursor: pointer;
  }
}
</style>
