<template>
  <div class="header">
    <div class="container responsive">
      <div class="row">
        <div class="col xs6 l3">
          <router-link class="main-page-link" :to="{ params: routerParams, name: 'main' }">
            <img src="/images/logo.svg?1" alt="element" />
          </router-link>
        </div>
        <div class="col xs6 l9 menu-wrap">
          <div
            v-on:click="sideNavShown = !sideNavShown"
            class="overlay"
            :class="{
              shown: sideNavShown,
            }"
          ></div>
          <a
            href="javascript: void(0);"
            class="burger-link"
            :class="{
              active: sideNavShown,
            }"
            v-on:click="sideNavShown = !sideNavShown"
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
          <div
            class="burger-menu"
            :class="{
              shown: sideNavShown,
            }"
          >
            <div class="row">
              <div class="col xs12 l10">
                <menu-template v-bind:router-params="routerParams" v-bind:menu-items="menu" />
              </div>
              <div class="col xs12 l2">
                <div class="languages">
                  <span
                    v-for="(locale, index) in locales.list"
                    :key="index"
                    class="language-selector"
                    v-on:click="$emit('localeChanged', locale.code)"
                    :class="{ active: selectedLocale === locale.code }"
                    >{{ locale.label }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuTemplate from "./menu";
const bodyNode = document.querySelector("body");

export default {
  name: "header-template",
  components: {
    menuTemplate,
  },
  props: {
    menu: Object,
    locales: Object,
    currentRoute: String,
    selectedLocale: String,
    routerParams: Object,
  },
  data() {
    return {
      sideNavShown: false,
    };
  },
  watch: {
    currentRoute() {
      this.sideNavShown = false;
    },
    sideNavShown(navShown) {
      bodyNode.style.overflow = navShown ? "hidden" : null;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.header {
  height: 100px;
  border-bottom: 2px solid #0b0b0b;

  a.main-page-link {
    display: block;
    position: relative;
    width: 200px;
    height: 98px;

    img {
      width: 240px;
      height: 38px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .menu-wrap {
    text-align: right;
    line-height: 98px;
    font-size: 0;
  }

  .burger-link {
    cursor: pointer;
    display: none;
    width: 40px;
    height: 27px;
    vertical-align: middle;
    position: relative;
    z-index: 10;

    span {
      display: block;
      height: 4px;
      width: 100%;
      background: #000000;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(2) {
        top: 12px;
      }

      &:nth-child(3) {
        top: 24px;
        width: 30px;
      }
    }

    &.active {
    }
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(7px);
    background: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 5;
  }

  .languages {
    line-height: 98px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;

    .language-selector {
      color: #aaaaaa;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 15px;
      }

      &.active {
        color: #000000;
      }
    }
  }
}

.tabletMinus .header {
  .burger-link {
    display: inline-block;

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 12px;
        }
        &:nth-child(2) {
          opacity: 0;
          width: 0;
        }
        &:nth-child(3) {
          width: 100%;
          transform: rotate(-45deg);
          top: 12px;
        }
      }
    }
  }
  .overlay {
    &.shown {
      display: block;
    }
  }

  .burger-menu {
    position: fixed;
    width: 320px;
    background: #ffffff;
    top: 0;
    right: -320px;
    min-width: 320px;
    height: 100%;
    z-index: 7;
    padding-top: 100px;
    line-height: 60px;
    transition: right 0.5s;
    overflow-x: hidden;
    overflow-y: auto;

    nav {
      padding: 0 20px;
      a {
        display: block;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #eeeeee;
      }
    }

    .languages {
      text-align: center;

      .language-selector {
        margin: 0 20px;
      }
    }

    &.shown {
      right: 0;
    }
  }
}

.smallest {
  a.main-page-link img {
    width: 165px;
  }
}
</style>
