<template>
  <div class="photos">
    <input type="file" name="photos" multiple accept=".png, .jpg, .jpeg" ref="input" @input="handleInput" />
    <div
      v-for="(number, index) of new Array(quantity)"
      v-bind:key="index"
      class="image-uploader"
      :class="{ 'has-image': selectedFiles[index] }"
      @click.self="selectImages(index)"
    >
      <div
        class="image-preview"
        v-if="selectedFiles[index]"
        :style="{ backgroundImage: `url('${selectedFiles[index]?.imageSource ?? ''}')` }"
      ></div>
      <span class="remover" @click="removeItem(index)" v-if="selectedFiles[index]"></span>
    </div>
  </div>
</template>

<script>
const statuses = Object.freeze({
  initial: 0,
  saving: 1,
  success: 2,
  failed: 3,
});

export default {
  name: "photos-uploader",
  props: {
    modelValue: String,
    label: String,
    quantity: Number,
    maxSizeKb: {
      type: Number,
      default: 4128,
    },
  },
  data() {
    return {
      selectedFiles: [],
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === statuses.initial;
    },
    isSaving() {
      return this.currentStatus === statuses.saving;
    },
    isSuccess() {
      return this.currentStatus === statuses.success;
    },
    isFailed() {
      return this.currentStatus === statuses.failed;
    },
  },
  methods: {
    selectImages() {
      this.$refs.input.click();
    },
    setValue(value) {
      this.$emit("update:modelValue", value);
    },
    removeItem(index) {
      this.selectedFiles.splice(index, 1);
      this.$refs.input.value = "";
    },
    fileAlreadySelected(file) {
      return !!this.selectedFiles.find(it => it.file.name === file.name && it.file.size === file.size);
    },
    addFile(index, file) {
      this.selectedFiles[index] = {
        file,
        status: null,
        imageSource: null,
      };

      const reader = new FileReader();
      reader.onload = () => (this.selectedFiles[index].imageSource = reader.result);
      reader.readAsDataURL(file);
    },
    handleInput({ target }) {
      let index = this.selectedFiles.length;
      let maxIndex = this.quantity - 1;

      for (const file of target.files) {
        if (index > maxIndex) {
          break;
        }

        if (file.size / 1024 > this.maxSizeKb) {
          console.log("very big size");
          continue;
        }

        const fileExists = this.fileAlreadySelected(file);

        if (!fileExists) {
          this.addFile(index, file);
          index++;
        }
      }

      this.setValue(target.value);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.photos {
  margin-bottom: 50px;
  font-size: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  input {
    display: none;
  }
}

.image-uploader {
  display: inline-block;
  background: url("/images/plus-white.png") center no-repeat #eeeeee;
  position: relative;
  height: 130px;
  width: 90px;

  &:not(.disabled) {
    cursor: pointer;

    &:hover:not(.has-image) {
      background-image: url("/images/plus-black.png");
    }
  }

  &.has-image {
    background-image: none;
    cursor: default;
  }

  .image-preview {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: center/cover no-repeat;
  }

  .remover {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 5;
    background: url("/images/cross.png") center no-repeat #ffffff;
  }
}

.tablet {
  .image-uploader {
    height: 106px;
    width: 74px;
  }
}

.phone {
  .image-uploader {
    height: 138px;
    width: 98px;
  }
}

.smallest {
  .image-uploader {
    height: 188px;
    width: 140px;
    margin-bottom: 20px;
  }
}
</style>
