<template>
  <div class="page-content">
    <div class="container">
      <div class="privacy container-paddings" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import backend from "../../services/backend";

export default {
  name: "tos",
  props: {
    locale: String,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    async updatePageData() {
      this.content = await backend.pageInfo("tos");
    },
  },
  mounted() {
    this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.privacy {
  ::v-deep(h1) {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
  }

  ::v-deep(h3) {
    font-size: 24px;
    margin: 0 0 24px 0;
  }

  ::v-deep(.row) {
    margin-bottom: 40px;
  }

  ::v-deep(div.ol) {
    padding-left: 40px;

    .li {
      font-size: 16px;
    }
  }

  ::v-deep(div.descr) {
    margin-bottom: 24px;
  }

  ::v-deep(.mark),
  ::v-deep(mark) {
    display: inline;
    padding: 0.05em;
    background-color: #fcf8e3;
  }
}

.phoneMinus {
  .privacy {
    ::v-deep(h1) {
      font-size: 30px;
    }
  }
}
</style>
