<template>
  <div class="page-content">
    <div class="container models-list" v-if="models">
      <div class="row">
        <div class="col xs6 m4 l3" v-for="model of models" v-bind:key="model.id">
          <router-link :to="{ params: { model: model.id, ...routerParams }, name: 'models-view' }" class="model">
            <div class="cover" :style="{ backgroundImage: `url('${model.cover}')` }">
              <div class="out-of-town" v-if="model.outOfTown.status">
                {{ model.outOfTown.city }}
              </div>
              <div class="params">
                <div class="row">
                  <div class="col xs3">
                    <span>{{ translations.height }}</span>
                    <span>{{ model.params.height }}</span>
                  </div>
                  <div class="col xs3">
                    <span>{{ translations.bust }}</span>
                    <span>{{ model.params.bust }}</span>
                  </div>
                  <div class="col xs3">
                    <span>{{ translations.waist }}</span>
                    <span>{{ model.params.waist }}</span>
                  </div>
                  <div class="col xs3">
                    <span>{{ translations.hips }}</span>
                    <span>{{ model.params.hips }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="name">{{ model.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backend from "../../../services/backend";

export default {
  name: "models-list",
  props: {
    locale: String,
    routerParams: Object,
  },
  data() {
    return {
      models: [],
      translations: null,
    };
  },
  methods: {
    async updatePageData() {
      const { models, translations } = await backend.pageInfo("models");

      this.models = models;
      this.translations = translations;
    },
  },
  async mounted() {
    await this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.models-list {
  .model {
    margin-bottom: 50px;
    display: block;
    color: #000000;

    .cover {
      background: center/cover no-repeat;
      height: 406px;
      position: relative;

      .out-of-town {
        position: absolute;
        right: 0;
        bottom: 0;
        font-weight: 500;
        min-height: 30px;
        line-height: 30px;
        width: auto;
        font-size: 16px;
        color: #000000;
        padding: 0 10px 0 40px;
        background: url("/images/plane.svg") 10px center no-repeat #ffffff;
      }

      &:hover {
        .params {
          opacity: 1;
        }
      }

      .params {
        z-index: 1;
        opacity: 0;
        position: absolute;
        color: #ffffff;
        font-size: 14px;
        height: 100px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        line-height: 34px;
        padding-top: 14px;
        transition: opacity 0.4s ease-in-out;

        .row {
          margin: 0;
          padding: 0 6px;
        }

        span {
          display: block;
          font-weight: bold;
          text-transform: uppercase;
        }

        span + span {
          font-weight: 400;
          font-size: 18px;
        }

        .col {
          text-align: center;
          padding: 0;
        }
      }
    }

    .name {
      padding-top: 15px;
      height: 36px;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

.laptop {
  .model {
    .cover {
      height: 326px;

      .params {
        height: 80px;
        font-size: 12px;
        line-height: 30px;
        padding-top: 10px;

        span + span {
          font-size: 14px;
        }
      }
    }
  }
}

.tablet {
  .model {
    .cover {
      height: 334px;
    }
  }
}

.tabletMinus {
  .params {
    display: none;
  }
}

.phone {
  .model {
    .cover {
      height: 305px;
    }
  }
}

.smallest {
  .model {
    .cover {
      height: 190px;

      .out-of-town {
        line-height: 22px;
      }
    }
  }
}
</style>
