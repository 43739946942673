import "./styles/main.scss";

import { createApp } from "vue";
import appTemplate from "./app.vue";

import router from "./router";
import { Vue3Mq } from "vue3-mq";

const app = createApp(appTemplate);

app.use(router);
app.use(Vue3Mq, {
  breakpoints: {
    smallest: 0,
    phone: 481,
    tablet: 769,
    laptop: 993,
    desktop: 1201,
  },
});

app.mount("#app");
