<template>
  <div class="page-content">
    <div id="map"></div>
    <div class="container contacts-container" v-if="contacts">
      <div class="contacts">
        <div class="row">
          <div class="col xs12 s6 l12" v-for="[index, contact] of contacts.entries()" v-bind:key="index">
            <div class="contact">
              <div>{{ contact.heading }}</div>
              <div v-html="contact.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backend from "../../services/backend";

export default {
  name: "contacts",
  props: {
    locale: String,
  },
  data() {
    return {
      mapConfig: null,
      map: null,
      mapMarker: null,
      contacts: null,
    };
  },
  methods: {
    async updatePageData() {
      const { contacts, map } = await backend.pageInfo("contacts");

      this.contacts = contacts;
      this.mapConfig = map;

      this.initMap();
    },
    initMap() {
      const mapElement = document.querySelector("script.google-map");

      if (mapElement && mapElement.dataset.locale === this.locale) {
        window.initMap();
        return;
      }

      mapElement?.remove();

      const script = document.createElement("script");

      script.className = "google-map";
      script.dataset.locale = this.locale;

      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.mapConfig.apiKey}&language=${this.locale}&callback=initMap`;
      script.async = true;

      document.head.appendChild(script);

      window.initMap = () => {
        const mapInstance = new window.google.maps.Map(document.getElementById("map"), {
          center: this.mapConfig.coordinates,
          zoom: 17,
          mapId: this.mapConfig.mapId,
        });

        this.map = mapInstance;
        this.mapMarker = this.createMapMarker(mapInstance);
      };
    },
    createMapMarker(map) {
      return new window.google.maps.Marker({
        position: this.mapConfig.coordinates,
        icon: this.mapConfig.mapPinIcon,
        map: map,
      });
    },
  },
  mounted() {
    this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.page-content {
  padding: 0;
  overflow: hidden;
}

.contacts {
  background: #ffffff;

  position: absolute;
  top: 100px;
  right: 100px;
  width: 380px;
  padding: 40px;

  .contact {
    padding-bottom: 35px;

    div:first-child {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }

    div + div {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.laptopPlus {
  .contacts-container {
    position: absolute;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
  }

  .contact {
    pointer-events: all;
  }
}

.laptop {
  .contacts {
    height: 100%;
    top: 0;
    right: -700px;
    width: 1000px;
  }
}

.tabletMinus {
  .contacts {
    position: unset;
    padding: 50px 0;
    width: 100%;
  }
}
#map {
  height: 800px;
}
</style>
