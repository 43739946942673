<template>
  <nav class="main-menu">
    <router-link class="tab-control" :to="routerUrl('models')">{{ menuItems.models }}</router-link>
    <router-link class="tab-control" :to="routerUrl('school')">{{ menuItems.school }}</router-link>
    <router-link class="tab-control" :to="routerUrl('be-a-model')">{{ menuItems.beModel }}</router-link>
    <router-link class="tab-control" :to="routerUrl('about')">{{ menuItems.about }}</router-link>
    <router-link class="tab-control" :to="routerUrl('contacts')">{{ menuItems.contacts }}</router-link>
  </nav>
</template>

<script>
export default {
  name: "main-menu",
  props: {
    menuItems: Object,
    routerParams: Object,
  },
  methods: {
    routerUrl(routeName) {
      return { params: this.routerParams, name: routeName };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.main-menu {
  line-height: 98px;
  font-size: 0;
  text-align: right;

  a {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-transform: uppercase;
    margin: 0 15px;
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
