<template>
  <div class="page-content">
    <div class="container">
      <div class="container-paddings">
        <h1>Заполни анкету будущей модели!</h1>
        <div class="requirements">
          <p>
            <span class="gender">Для девушек</span>
            <span class="dash">&mdash;</span>
            возраст от 12 до 20 лет, рост от 168 см, бедра до 94 см
          </p>
          <p>
            <span class="gender">Для юношей</span>
            <span class="dash">&mdash;</span>
            возраст от 15 до 25 лет, рост от 182 см, размер одежды 46 - 52
          </p>
        </div>
        <div class="description">
          <p>
            К анкете прикрепите 4 максимально естественные фотографии &mdash; портреты крупным планом и в полный рост.
          </p>
          <p>Если ваша заявка будет успешной, мы вам ответим.</p>
        </div>
      </div>
      <div class="request-form">
        <form method="post" @submit="formSubmit">
          <div class="row">
            <div class="col m12 l6">
              <div class="row">
                <div class="col xs12 m6" v-for="(input, index) of formInputs" v-bind:key="index">
                  <div class="input-wrap">
                    <text-input
                      v-model="requestForm[input.name]"
                      :label="input.label"
                      :type="input.type || 'text'"
                      :required="!!input.required"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col xs12 m12 l6 right-side-wrap">
              <div class="form-right-side">
                <div class="row">
                  <div class="col xs12 m6 l12">
                    <div class="range-wrap">
                      <range v-model="requestForm.height" label="Рост" :min="160" :max="200" />
                    </div>
                  </div>
                  <div class="col xs12 m6 l12">
                    <photos-uploader ref="photosUploader" :quantity="4" />
                  </div>
                  <div class="col xs12 m6 offset-m6 l12 button-wrap">
                    <button :class="{ disabled: sendingProcess }" class="button">Отправить заявку</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col xs12 m6" style="position: relative">
              <div class="personal-data-accept-wrap">
                <checkbox v-model="requestForm.acceptPersonal" :label="personalDataDescription" :required="true" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="example-photos">
        <div class="row">
          <div v-for="(source, index) of exampleImages" v-bind:key="index" class="col xs6 l3">
            <img :src="source" :alt="`Пример фото №${index + 1}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from "../ui/text-input";
import range from "../ui/range";
import checkbox from "../ui/checkbox";
import photosUploader from "../ui/photos-uploader";
import backend from "../../services/backend";
import Swal from "sweetalert2";

export default {
  name: "be-a-model",
  components: {
    textInput,
    range,
    checkbox,
    photosUploader,
  },
  data() {
    return {
      sendingProcess: false,
      formInputs: [
        {
          name: "name",
          label: "Имя",
          required: true,
        },
        {
          name: "lastName",
          label: "Фамилия",
          required: true,
        },
        {
          name: "age",
          label: "Возраст",
          required: true,
        },
        {
          name: "instagramLink",
          label: "Instagram",
        },
        {
          name: "email",
          label: "Почта",
          type: "email",
          required: true,
        },
        {
          name: "phone",
          label: "Телефон",
          required: true,
        },
      ],
      requestForm: {
        name: "",
        lastName: "",
        age: "",
        instagramLink: "",
        email: "",
        phone: "",
        acceptPersonal: false,
        height: 170,
      },
      personalDataDescription: "Даю согласие на обработку моих персональных данных",
      exampleImages: [
        "/images/be-a-model-example-1.png?1",
        "/images/be-a-model-example-2.png?1",
        "/images/be-a-model-example-3.png?1",
        "/images/be-a-model-example-4.png?1",
      ],
    };
  },
  methods: {
    formSubmit: function (event) {
      event.preventDefault();

      this.sendingProcess = true;

      const images = this.$refs.photosUploader.selectedFiles;

      backend
        .sendBeModel(this.requestForm, images)
        .then(() => {
          Swal.fire("Успех!", "Ваша заявка отправлена!", "success");
        })
        .catch(() => {
          Swal.fire("Ошибка!", "Не удалось отправить заявку, попробуйте позднее!", "error");
        })
        .finally(() => {
          this.sendingProcess = false;
        });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.laptop {
  .personal-data-accept-wrap {
    .checkbox {
      label {
        font-size: 14px;
      }
    }
  }
}

.phoneMinus {
  .personal-data-accept-wrap {
    .checkbox {
      label {
        font-size: 14px;
        padding-left: 4px;
      }
    }
  }
}

.smallest {
  .personal-data-accept-wrap {
    .checkbox {
      label {
        padding-left: 20px;
        text-align: center;
      }
    }
  }
}

.tablet {
  .range-wrap {
    padding-top: 18px;
  }
}

.tablet,
.smallest {
  .personal-data-accept-wrap {
    input {
      top: 4px;
    }

    .state-view {
      vertical-align: top;
      margin-top: 4px;
    }
  }
}
</style>

<style lang="scss" rel="stylesheet/scss" scoped>
h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 50px 0;
}

p {
  margin: 0;
}

span.dash {
  padding: 0 8px 0 10px;
}

.request-form {
  position: relative;
  margin-bottom: 95px;

  .text-input {
    margin-bottom: 50px;
  }

  .range-wrap {
    padding-top: 5px;
    margin-bottom: 55px;
  }

  button {
    width: 100%;
  }

  .personal-data-accept-wrap {
    width: 100%;
  }
}

.requirements {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 50px;

  span.gender {
    font-size: 18px;
  }

  p {
    margin-bottom: 20px;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 90px;

  p {
    margin-bottom: 15px;
  }
}

.example-photos {
  .col {
    margin-bottom: 10px;
  }
  img {
    width: 100%;
  }
}

.desktop {
  .input-wrap {
    width: 250px;
  }
}

.laptop {
  .right-side-wrap {
    display: flex;
    justify-content: flex-end;
  }
}

.tablet {
  .personal-data-accept-wrap {
    margin-top: -49px !important;
  }
}

.phone {
  .personal-data-accept-wrap {
    margin-top: -130px;
  }

  .button-wrap {
    margin-top: 80px;
  }
}

.smallest {
  .personal-data-accept-wrap {
    margin-top: -150px;
  }

  .button-wrap {
    margin-top: 80px;
  }
}

.laptopPlus {
  .form-right-side {
    width: 420px;
  }
}

.tabletPlus {
  .personal-data-accept-wrap {
    position: absolute;
    margin-top: -35px;
  }
}
</style>
