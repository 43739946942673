<template>
  <div class="page-content">
    <div class="container" v-if="model">
      <a href="javascript: void(0);" @click="goBack" class="back-link">{{ translations.backLink }}</a>
      <div class="row">
        <div class="col xs12 m6 xl5">
          <div class="cover" :style="{ backgroundImage: `url('${model.cover}')` }">
            <div class="out-of-town" v-if="model.outOfTown.status">
              {{ model.outOfTown.city }}
            </div>
          </div>
        </div>
        <div class="col xs12 m6 xl7">
          <div class="name ellipsis">
            {{ model.name }}
          </div>
          <div class="params">
            <div class="row" v-for="(parameter, index) of paramsOrdering" v-bind:key="index">
              <div class="col xs12">
                <div class="param">
                  <div class="row">
                    <div class="col xs6 xl4 param-name">{{ translations.params[parameter] }}</div>
                    <div class="col xs6 xl8 param-value">{{ model.parameters[parameter] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row model-interact-links">
            <div class="col xs12 xl8">
              <div class="row">
                <div class="col xs12 s6 external-link-wrap">
                  <a class="pdf-file" target="_blank" :href="compCardLink">{{ translations.compCard }}</a>
                </div>
                <div class="col xs12 s6 external-link-wrap instagram-wrap">
                  <a class="instagram" target="_blank" :href="model.instagramLink">Instagram</a>
                </div>
                <div class="col xs12 cooperate-wrap">
                  <a class="button" href="javascript: void(0);" @click.self="formShown = true">{{
                    translations.cooperate
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="model-media">
        <div class="media-controls container-paddings">
          <div class="tab-control-wrap" v-for="(tab, index) of existsTabs" v-bind:key="index">
            <span v-on:click="activeTab = tab" class="tab-control" :class="{ active: activeTab === tab }">{{
              translations.tabs[tab]
            }}</span>
          </div>
        </div>
        <div class="media-tabs">
          <div class="media-tab book" v-if="model.media.book.length" :class="{ shown: activeTab === 'book' }">
            <div class="row">
              <div v-for="(image, index) of model.media.book" v-bind:key="index" class="col xs6">
                <div class="model-list-image" :style="{ backgroundImage: `url('${image}')` }"></div>
              </div>
            </div>
          </div>
          <div
            class="media-tab snapshots"
            v-if="model.media.snapshots.length"
            :class="{ shown: activeTab === 'snapshots' }"
          >
            <div class="row">
              <div v-for="(image, index) of model.media.snapshots" v-bind:key="index" class="col xs6">
                <div class="model-list-image" :style="{ backgroundImage: `url('${image}')` }"></div>
              </div>
            </div>
          </div>
          <div class="media-tab video" v-if="model.media.video.length" :class="{ shown: activeTab === 'video' }">
            <div class="row">
              <div v-for="(youtubeLink, index) of model.media.video" v-bind:key="index" class="col xs12 m6">
                <iframe
                  :src="youtubeLink + `?hl=${locale}`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cooperate-form" :class="{ shown: formShown }">
    <div class="heading">Отправьте заявку и&nbsp;мы&nbsp;свяжемся с&nbsp;Вами для уточнения деталей</div>
    <form>
      <div class="inputs-wrap">
        <div class="text-input-wrap">
          <text-input v-model="formInputs.name" label="Имя" type="text" :required="true" />
        </div>
        <div class="text-input-wrap">
          <text-input v-model="formInputs.email" label="Почта" type="text" :required="true" />
        </div>
        <div class="text-input-wrap">
          <text-input v-model="formInputs.phone" label="Телефон" type="text" :required="true" />
        </div>
        <div class="checkbox-wrap">
          <checkbox
            v-model="formInputs.agree"
            label="Даю согласие на обработку моих персональных данных"
            :required="true"
          />
        </div>
        <button class="button">Отправить</button>
      </div>
    </form>
  </div>
  <div class="backdrop" :class="{ shown: formShown }" @click.self="formShown = false"></div>
</template>

<script>
import backend from "../../../services/backend";
import textInput from "../../ui/text-input";
import checkbox from "../../ui/checkbox";

export default {
  name: "model-view",
  props: {
    locale: String,
    routerParams: Object,
  },
  components: {
    textInput,
    checkbox,
  },
  computed: {
    existsTabs() {
      return this.tabs.filter(tab => this.model.media[tab].length);
    },
    modelId() {
      return this.$route.params.model;
    },
    compCardLink() {
      return `/${this.locale}/models/${this.modelId}/comp-card`;
    },
  },
  data() {
    return {
      activeTab: null,
      tabs: ["book", "snapshots", "video"],
      paramsOrdering: ["height", "bust", "waist", "hips", "shoes", "hair", "eyes"],
      model: null,
      translations: null,
      formInputs: {
        name: "",
        email: "",
        phone: "",
        agree: false,
      },
      formShown: false,
    };
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    goBack() {
      if (this.hasHistory()) {
        this.$router.go(-1);
      } else {
        this.$router.push({ params: this.routerParams, name: "models" });
      }
    },
    async updatePageData() {
      const { model, translations } = await backend.pageInfo(`models/${this.modelId}`);

      this.model = model;
      this.translations = translations;

      this.activeTab = this.existsTabs[0];
    },
  },
  async mounted() {
    await this.updatePageData();
  },
  watch: {
    locale() {
      this.updatePageData();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.page-content {
  padding-top: 0;
  padding-bottom: 0;
}

.cooperate-form {
  position: fixed;
  width: 550px;
  height: 640px;
  background: #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s opacity ease-in-out;

  &.shown {
    opacity: 1;
    pointer-events: unset;
  }

  .heading {
    font-size: 18px;
    font-weight: 700;
    margin: 70px auto 60px;
    width: 435px;
    text-align: center;
  }

  form > div {
    width: 280px;
    margin: 0 135px;
    text-align: center;

    .text-input-wrap {
      margin-bottom: 40px;
    }

    .checkbox-wrap {
      ::v-deep(label) {
        font-size: 14px;
        padding-left: 14px;
      }
    }

    .button {
      margin: 40px auto 0;
    }
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s opacity ease-in-out;

  &.shown {
    opacity: 1;
    pointer-events: unset;
  }
}

.container {
  padding-top: 120px;
}

.model-media {
  margin-top: 200px;

  .media-controls {
    border-bottom: 2px solid #000000;
    font-size: 0;

    .tab-control-wrap {
      display: inline-block;

      &:not(:last-child) {
        padding-right: 96px;
      }

      .tab-control {
        padding-bottom: 20px;
      }
    }

    span {
      display: inline-block;
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;

      &.active {
        cursor: default;
      }
    }
  }

  .media-tabs {
    padding-top: 100px;

    .model-list-image {
      height: 720px;
      margin-bottom: 200px;
      background: center/cover no-repeat;
    }

    .video iframe {
      width: 100%;
      height: 326px;
      margin-bottom: 100px;
    }

    .media-tab {
      display: none;

      &.shown {
        display: block;
      }
    }
  }
}

.cover {
  height: 640px;
  width: 440px;
  max-width: 100%;
  background: center/cover no-repeat;
  position: relative;

  .out-of-town {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    width: auto;
    font-size: 16px;
    color: #000000;
    padding: 0 20px 0 50px;
    background: url("/images/plane.svg") 10px center no-repeat #ffffff;
  }
}

.name {
  line-height: 50px;
  font-size: 44px;
  text-transform: capitalize;
  font-weight: 700;
}

.params {
  padding-top: 40px;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 56px;

  .param {
    border-bottom: 1px solid #eeeeee;
  }

  .param-name {
    color: #000000;
    font-weight: 700;
  }

  .param-value {
    font-weight: 400;
  }
}

.external-link-wrap {
  font-size: 0;

  a {
    font-size: 18px;
    line-height: 40px;
    height: 40px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    display: inline-block;
    padding-right: 53px;
    background: right 12px center no-repeat;

    &.pdf-file {
      background-image: url("/images/download-icon.svg");
    }

    &.instagram {
      background-image: url("/images/instagram-icon.svg");
    }
  }
}

.model-interact-links {
  padding-top: 32px;
}

.cooperate-wrap {
  padding-top: 30px;

  .button {
    width: 380px;
    padding: 0;
  }
}

a.back-link {
  text-transform: uppercase;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  display: inline-block;
  padding-left: 45px;
  position: absolute;
  left: 10px;
  top: 50px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 30px;
    background: url("/images/arrow-black.png") left center no-repeat;
    transform: rotate(180deg);
    left: 0;
    top: 0;
  }
}

.laptop {
  .model-media {
    margin-top: 150px;

    .tab-control-wrap {
      &:not(:last-child) {
        padding-right: 81px;
      }
    }
  }

  .media-tabs {
    .model-list-image {
      height: 586px;
      margin-bottom: 100px;
    }

    .video iframe {
      height: 264px;
    }
  }

  .cover {
    height: 640px;
  }

  .name {
    font-size: 30px;
  }

  .params {
    padding-top: 20px;
    padding-left: 10px;
  }

  .model-interact-links {
    padding-top: 62px;

    .cooperate-wrap {
      padding-top: 20px;

      .button {
        width: 392px;
      }
    }
  }
}
.laptopMinus {
  .model-media {
    margin-bottom: 50px;
  }
}

.tablet {
  .media-controls span {
    font-size: 20px;
  }

  .model-media {
    margin-top: 150px;
    .tab-control-wrap {
      &:not(:last-child) {
        padding-right: 49px;
      }
    }
  }

  .media-tabs {
    .model-list-image {
      height: 440px;
      margin-bottom: 100px;
    }

    .video iframe {
      height: 200px;
    }
  }

  .cover {
    height: 515px;
  }
  .name {
    font-size: 25px;
  }

  .params {
    padding-top: 10px;
  }

  .cooperate-wrap .button {
    width: 330px;
  }
}

.tabletMinus {
  .external-link-wrap {
    a {
      height: 50px;
      line-height: 50px;
      padding-right: 46px;
    }
  }

  .cooperate-wrap {
    padding-top: 0;
  }
}

.phone {
  .media-controls span {
    font-size: 16px;
  }

  .media-tabs {
    padding-top: 50px;

    .model-list-image {
      height: 262px;
      margin-bottom: 50px;
    }

    .video iframe {
      height: 248px;
      margin-bottom: 50px;
    }
  }

  .cover {
    height: 638px;
  }

  .name {
    font-size: 26px;
    margin-top: 30px;
  }

  .cooperate-wrap {
    padding-top: 30px;
  }

  .external-link-wrap {
    &.instagram-wrap {
      text-align: right;
    }
  }
}

.phoneMinus {
  .params {
    .col + .col {
      text-align: right;
    }
  }

  .model-media {
    margin-top: 100px;
  }

  .media-controls {
    .tab-control-wrap {
      padding: 0 !important;
      width: 33.33%;

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  .cooperate-wrap {
    .button {
      width: 100%;
    }
  }
}

.smallest {
  .media-controls span {
    font-size: 16px;
  }

  .media-tabs {
    padding-top: 50px;

    .model-list-image {
      height: 163px;
      margin-bottom: 50px;
    }

    .video iframe {
      height: 170px;
      margin-bottom: 50px;
    }
  }

  .cover {
    height: 406px;
  }

  .name {
    font-size: 20px;
    margin-top: 20px;
  }

  .params {
    padding-top: 0;
  }

  .external-link-wrap {
    text-align: center !important;
    padding: 0 0 20px 0;
  }
}
</style>
